<template>
    <div>
        <img src="@/assets/solutionpdf/clothing_1.jpg" alt="">
        <img src="@/assets/solutionpdf/clothing_2.jpg" alt="">
        <img src="@/assets/solutionpdf/clothing_3.jpg" alt="">
        <img src="@/assets/solutionpdf/clothing_4.jpg" alt="">
        <img src="@/assets/solutionpdf/clothing_5.jpg" alt="">
        <img src="@/assets/solutionpdf/clothing_6.jpg" alt="">
        <img src="@/assets/solutionpdf/clothing_7.jpg" alt="">
    </div>
</template>

<script>
export default {
    name: "clothing-solution"
}
</script>

<style scoped>
img{
    width: 100%;
}
</style>